<template v-if="render">

  <div class="band">
    <!-- políticas -->
    <Modal :visible="modal_politics" @close="modal_politics = false"></Modal>
    <!-- /políticas -->

    <v-card class="position-relative" elevation="0" tile>
      <template v-if="band.background !== null">
        <template v-if="band.background.image">
          <v-img
            :src="
              'https://www.landing-xonoro-cms.actstudio.xyz/storage/uploads' +
              band.background.path
            "
            class="band__background"
          ></v-img>
        </template>
        <template v-else-if="band.background.video == true">
          <video
            autoplay
            muted
            loop
            height="100%"
            width="100%"
            style="object-fit: cover;"
            playsinline
            class="band__background"
          >
            <source
              :src="
                'https://www.landing-xonoro-cms.actstudio.xyz/storage/uploads' +
                band.background.path
              "
              type="video/mp4"
            />
          </video>
        </template>
      </template>

      <v-container fluid style="height: 100vh" class="position-relative px-4 px-sm-12">
        <v-row class="fill-height" align="end" justify="center" no-gutters>
          <v-col cols="12" sm="auto" class="pa-0">
            <div>
              <template v-if="band.cover.cover_img != null">
                <template v-if="band.cover.cover_img.image == true">
                  <v-img
                    :src="
                      'https://www.landing-xonoro-cms.actstudio.xyz/storage/uploads/' +
                      band.cover.cover_img.path
                    "
                    contain
                    class="band__img-cover"
                  ></v-img>
                </template>
                <template v-else-if="band.cover.cover_img.video == true">
                  <video class="band__img-cover" autoplay loop mute playsinline>
                    <source
                      :src="
                        'https://www.landing-xonoro-cms.actstudio.xyz/storage/uploads/' +
                        band.cover.cover_img.path
                      "
                      type="video/mp4"
                    />
                  </video>
                </template>
              </template>
            </div>
            <div class="band__box-btns-texts text-center text-sm-left">
              <v-row no-gutters justify="center">
                <v-col
                  cols="12"
                  sm="auto"
                  class="mb-5 mb-sm-0"
                  v-for="(text_btn, tb) in band.texts_btns"
                  v-bind:class="{'padding-right-custom': band.texts_btns.length > 1}"
                  v-bind:key="tb"
                >
                  <template v-if="text_btn.value.call_to_action !== null">
                    <a
                      :href="text_btn.value.call_to_action"
                      target="_blank"
                      v-bind:class="{'white--text': band.color_texts == 'blanco', 'black--text': band.color_texts == 'negro'}"
                    >
                      <span class="texts inter-bold" v-bind:class="{'white--text': band.color_texts == 'blanco', 'black--text': band.color_texts == 'negro'}">{{
                        text_btn.value.text
                      }}</span>
                    </a>
                  </template>
                  <template v-else>
                    <span class="texts inter-bold" v-bind:class="{'white--text': band.color_texts == 'blanco', 'black--text': band.color_texts == 'negro'}">{{
                      text_btn.value.text
                    }}</span>
                  </template>
                </v-col>
              </v-row>
            </div>
            <div class="band__box-btns-social-networks">
              <v-row no-gutters justify="center">
                <v-col
                  cols="auto"
                  class="band__box-btns-social-networks__column"
                  v-bind:class="{'padding-right-custom': social_media.length == 0}"
                  v-for="(social_media, sc) in band.social_networks"
                  v-bind:key="sc"
                >
                  <template v-if="social_media.value.icon !== null">
                    <a
                      :href="social_media.value.call_to_action"
                      target="_blank"
                    >
                      <v-img
                        :src="
                          'https://www.landing-xonoro-cms.actstudio.xyz/' +
                          social_media.value.icon.path
                        "
                        class="band__box-btns-social-networks__column--icon"
                        contain
                      ></v-img>
                    </a>
                  </template>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12" class="mb-1 mb-sm-4">
            <v-row no-gutters justify="start" class="line-height-custom-521">
              <v-col cols="12">
                <span class="rewrite-font inter-bold" v-bind:class="{'white--text': band.color_texts == 'blanco', 'black--text': band.color_texts == 'negro'}"><span>Powered by</span> <span class="inter-bold powered-padding">Xonoro + ACT.</span></span>
              </v-col>
              <v-col cols="12" class="margin-top-custom">
                <span class="rewrite-font" v-bind:class="{'white--text': band.color_texts == 'blanco', 'black--text': band.color_texts == 'negro'}"
                  >Para más detalles revisa nuestra
                  <span class="underline cursor-pointer" @click="modal_politics = true">política de privacidad</span></span
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Modal from '../components/Modal'

export default {
  name: 'Band',
  components: {
    Modal
  },
  data () {
    return {
      render: false,
      band: {},
      modal_politics: false
    }
  },
  beforeCreate () {
    fetch(
      'https://www.landing-xonoro-cms.actstudio.xyz/api/collections/get/bands',
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          filter: {
            url: this.$route.params.band
          }
        })
      }
    )
      .then((res) => res.json())
      .then((res) => (this.band = res.entries[0]))
      .then((res) => (this.render = true))
      .catch((error) => console.log(error.message))
  }
}
</script>
